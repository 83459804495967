<template>
  <div class="dashboard-container">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'DashboardContainer',
};
</script>

<style scoped>
</style>
